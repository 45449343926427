<script>
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";
import Axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import Swal from "sweetalert2";
import { CountTo } from "vue3-count-to";

import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
import FieldVisitConfigServices from "../../services/FieldVisitConfigServices";
import SmsSender from "./parts/sms-sender.vue";
import FieldVisitServices from "@/services/FieldVisitServices";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Détails de la visite",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      isCreateModalOpen: false,
      title: "Détails de la visite",
      items: [
        {
          text: "Les visites",
          href: "/s/",
        },
        {
          text: "Informations sur la visite",
          active: true,
        },
      ],
      page: 1,
      perPage: 8,
      pages: [],
      value: null,
      statuscategory: "All",
      value1: null,
      searchQuery: null,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      date: null,
      date2: null,
      defaultOptions: {
        animationData: animationData,
      },
      currentFieldVisitConfig: {},
      isStatus: null,
      isPayment: null,
      visitFields: {
        field_id: 1,
        trip_mode: "car",
        amount: 0,
        sheduled_at: null,
      },
      fields: {
        email: "",
        phone: "",
        fullname: "",
        city: "",
        quater: "",
        wished_date: "",
        trip_mode: "",
        time_slot: "",
      },
      isCreateVisitModalOpen: false,
      isUpdatePaymentModalOpen: false,
      currentProspect: null,
      paymentNotes: null,
      selectedRows: [],
    };
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    CountTo,
    // Multiselect,
    SmsSender,
    flatPickr,
  },
  computed: {
    prospects() {
      return this.currentFieldVisitConfig?.prospects ?? [];
    },
    displayedPosts() {
      return this.paginate(this.prospects);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.id.toLowerCase().includes(search) ||
            data.fullname.toLowerCase().includes(search) ||
            data.email.toLowerCase().includes(search) ||
            data.phone.toLowerCase().includes(search) ||
            data.subscriptionDate.toLowerCase().includes(search) ||
            data.status.toLowerCase().includes(search)
          );
        });
      } else if (this.date !== null) {
        if (this.date !== null) {
          var date1 = this.date.split(" to ")[0];
          var date2 = this.date.split(" to ")[1];
        }
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.subscriptionDate.slice(0, 12)) >= new Date(date1) &&
            new Date(data.subscriptionDate.slice(0, 12)) <= new Date(date2)
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.value !== null) {
        return this.displayedPosts.filter((data) => {
          if (data.status === this.value) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.value1 !== null) {
        return this.displayedPosts.filter((data) => {
          if (data.payment === this.value1) {
            return data;
          } else {
            return null;
          }
        });
      } else if (
        this.date !== null &&
        this.value !== null &&
        this.value1 !== null
      ) {
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.subscriptionDate.slice(0, 12)) >= new Date(date1) &&
            new Date(data.subscriptionDate.slice(0, 12)) <= new Date(date2) &&
            data.status === this.value &&
            data.payment === this.value1
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else {
        return this.displayedPosts;
      }
    },
    canAddVisit() {
      return this.selectedRows.length > 0;
    },
  },
  watch: {},
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },

  mounted() {},
  async beforeRouteEnter(to) {
    let prospects = [];
    let currentVisit = null;
    await FieldVisitConfigServices.getById(to.params.id)
      .then((data) => {
        currentVisit = data.visit;
        const monthNames = [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aou",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        data.visit.prospects.forEach((row) => {
          var dd = new Date(row.created_at);
          row.subscriptionDate =
            dd.getDate() +
            " " +
            monthNames[dd.getMonth()] +
            ", " +
            dd.getFullYear() +
            " - " +
            dd.toLocaleTimeString();
          prospects.push(row);
        });
      })
      .catch((er) => {
        console.log(er);
      });

    localStorage.setItem(
      "@CURRENT_FIELD_VISIT",
      JSON.stringify({ ...currentVisit, prospects })
    );
  },
  beforeMount() {
    this.currentFieldVisitConfig = JSON.parse(
      localStorage.getItem("@CURRENT_FIELD_VISIT") || "{}"
    );
  },
  methods: {
    handleOpenUpdatePaymentModalOpen(prospect) {
      this.currentProspect = prospect;
      this.isUpdatePaymentModalOpen = true;
    },

    async handleAdminPay() {
      FieldVisitConfigServices.adminPay({
        prospect_id: this.currentProspect.id,
        comment: this.paymentNotes,
      })
        .then((res) => {
          console.log(res);
          if (!res.error) {
            Swal.fire({
              title: "Confirmation",
              text: "Paiement enregistré avec succès !",
              icon: "success",
            });

            this.paymentNotes = null;
          }

          this.getCurrentVisit();
        })
        .catch(() => {
          Swal.fire({
            title: "Erreur",
            text: "Erreur lors de la validation du paiement.",
            icon: "error",
          });
        });
    },
    async handleCreateFieldVisit() {
      FieldVisitServices.create(
        {
          ...this.visitFields,
          sheduled_at: this.visitFields.sheduled_at + ":00.000+01:00",
        },
        this.selectedRows.map((item) => item.id)
      ).then((res) => {
        console.log(res);
        if (!res.error) {
          Swal.fire({
            title: "Confirmation",
            text: "Visite créée avec succès",
            icon: "success",
          });

          this.visitFields = {
            field_id: null,
            trip_mode: null,
            amount: null,
            sheduled_at: null,
          };

          this.selectedRows = [];

          this.getCurrentVisit();
        }
      });
    },
    handleOpenCreate() {
      this.isCreateVisitModalOpen = true;
      console.log(this.selectedRows);
    },
    async handleCreateSubscription() {
      this.$startLoading();
      await Axios.post(
        "/admin/fields/visit-configs/" +
          this.currentFieldVisitConfig.id +
          "/init-subscribe",
        {
          fullname: this.fields.fullname,
          phone: this.fields.phone,
          email: this.fields.email,
          city: this.fields.city,
          quater: this.fields.quater,
          wished_date: this.fields.wished_date,
          trip_mode: JSON.stringify(this.fields.trip_mode),
          time_slot: JSON.stringify(this.fields.time_slot),
        }
      )
        .then(() => {
          this.fields = {
            email: "",
            phone: "",
            fullname: "",
            city: "",
            quater: "",
            wished_date: "",
            trip_mode: "",
            time_slot: "",
          };
          this.$saSuccess("Le prospect a été ajouté !");
        })
        .catch(() => {
          this.$saError("Erreur lors de l'enregistrement du prospect !");
        })
        .finally(() => {
          // this.$stopLoading();
          this.$emit("reloadDatas");
        });
    },
    async getCurrentVisit() {
      await FieldVisitConfigServices.getById(this.$route.params.id)
        .then((data) => {
          const currentVisitTemp = data.visit;
          const prospectsTemp = [];
          const monthNames = [
            "Jan",
            "Fev",
            "Mar",
            "Avr",
            "Mai",
            "Juin",
            "Juil",
            "Aou",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ];
          data.visit.prospects.forEach((row) => {
            var dd = new Date(row.created_at);
            row.subscriptionDate =
              dd.getDate() +
              " " +
              monthNames[dd.getMonth()] +
              ", " +
              dd.getFullYear();
            prospectsTemp.push(row);
          });

          this.currentFieldVisitConfig = {
            ...currentVisitTemp,
            prospects: prospectsTemp,
          };
        })
        .catch((er) => {
          console.log(er);
        });
    },
    onChangeStatus(e) {
      this.isStatus = e;
    },
    onChangePayment(e) {
      this.isPayment = e;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.prospects.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(prospects) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return prospects.slice(from, to);
    },
    SearchData() {
      this.resultQuery;
      // var isstatus = document.getElementById("idStatus").value;
      //   var payment = document.getElementById("idPayment").value;
    },
    changecategory(data) {
      console.log(data);
      this.statuscategory = data;
    },
    toggleRowSelection(prospect) {
      if (!prospect.has_paid || prospect.field_visit_id) return;

      const index = this.selectedRows.findIndex(
        (row) => row.id === prospect.id
      );
      if (index === -1) {
        this.selectedRows.push(prospect);
      } else {
        this.selectedRows.splice(index, 1);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div v-if="currentFieldVisitConfig">
      <b-modal
        v-model="isCreateModalOpen"
        hide-footer
        title="Ajout d'une souscription"
        class="v-modal-custom"
        header-class="bg-light p-3"
      >
        <form action="#" id="addform">
          <div class="mb-3">
            <label for="email-field" class="form-label"> Nom complet </label>
            <input
              type="text"
              id="fullname"
              class="form-control"
              placeholder="Entrez le nom complet"
              required
              v-model="fields.fullname"
            />
          </div>

          <div class="mb-3">
            <label for="status-field" class="form-label"> E-mail </label>
            <input
              type="email"
              id="email"
              class="form-control"
              placeholder="Entrez votre adresse E-mail"
              required
              v-model="fields.email"
            />
          </div>

          <div class="mb-3">
            <label for="amount" class="form-label">Téléphone</label>
            <input
              type="tel"
              id="phone"
              class="form-control"
              placeholder="Entrez le numéro de téléphone"
              required
              v-model="fields.phone"
            />
          </div>

          <div class="mb-3">
            <label for="city-field" class="form-label"> Ville </label>
            <input
              type="text"
              id="city"
              class="form-control"
              placeholder="Entrez la ville"
              required
              v-model="fields.city"
            />
          </div>

          <div class="mb-3">
            <label for="quater-field" class="form-label"> Quartier </label>
            <input
              type="text"
              id="quater"
              class="form-control"
              placeholder="Entrez le quartier"
              required
              v-model="fields.quater"
            />
          </div>

          <div class="mb-3">
            <label for="wished-date-field" class="form-label">
              Date souhaitée
            </label>
            <input
              type="date"
              id="wished_date"
              class="form-control"
              required
              v-model="fields.wished_date"
            />
          </div>

          <div class="mb-3">
            <label for="trip-mode-field" class="form-label">
              Mode de voyage
            </label>
            <select
              id="trip_mode"
              class="form-control"
              v-model="fields.trip_mode"
              required
            >
              <option
                v-for="mode in currentFieldVisitConfig.trip_modes"
                :key="mode"
                :value="mode"
              >
                {{ mode }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label for="time-slot-field" class="form-label">
              Plage horaire
            </label>
            <select
              id="time_slot"
              class="form-control"
              v-model="fields.time_slot"
              required
            >
              <option
                v-for="slot in currentFieldVisitConfig.time_slots"
                :key="slot"
                :value="slot"
              >
                {{ slot }}
              </option>
            </select>
          </div>

          <div class="modal-footer v-modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                type="button"
                id="closemodal"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleCreateSubscription"
              >
                Enregistrer le prospect
              </button>
            </div>
          </div>
        </form>
      </b-modal>

      <b-modal
        v-model="isCreateVisitModalOpen"
        hide-footer
        title="Ajout d'une visite"
        class="v-modal-custom"
        header-class="bg-light p-3"
      >
        <form action="#" id="addform">
          <div class="mb-3">
            <label for="status-field" class="form-label">
              Choisir le site
            </label>
            <select
              class="form-control"
              data-trigger
              id="statusfield"
              v-model="visitFields.field_id"
            >
              <option :value="currentFieldVisitConfig.field.id">
                {{ currentFieldVisitConfig.field.name }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label for="email-field" class="form-label"> Date et heure </label>
            <input
              type="datetime-local"
              data-bs-provider="flatpickr"
              data-bs-date-format="d.m.y"
              data-bs-enable-time
              id="date"
              class="form-control"
              placeholder="Entrer l'heure et la date"
              required
              v-model="visitFields.sheduled_at"
            />
          </div>

          <div class="mb-3">
            <label for="status-field" class="form-label">
              Mode de transport
            </label>
            <select
              class="form-control"
              data-trigger
              id="statusfield"
              v-model="visitFields.trip_mode"
            >
              <option value="car">Voiture</option>
              <option value="bus">Bus</option>
              <option value="bycicle">Moto</option>
            </select>
          </div>

          <div class="mb-3">
            <label for="amount" class="form-label">Montant</label>
            <input
              type="tel"
              id="phone"
              class="form-control"
              placeholder="Entrer le coût de la visite"
              required
              v-model="visitFields.amount"
            />
          </div>
          <div class="modal-footer v-modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                type="button"
                id="closemodal"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleCreateFieldVisit"
              >
                Enregistrer la visite
              </button>
            </div>
          </div>
        </form>
      </b-modal>

      <div class="row">
        <div class="col-xl-3 col-sm-6">
          <div class="card card-animate">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="fw-medium text-muted mb-0">Montant collecté</p>
                  <h2 class="mt-4 ff-secondary fw-semibold">
                    <count-to
                      :startVal="0"
                      :endVal="
                        currentFieldVisitConfig.prospects
                          .filter((item) => item.status == '1')
                          .reduce(
                            (total, item) =>
                              total + (item.paid_by_admin ? 0 : item.amount),
                            0
                          )
                      "
                      :duration="3000"
                    ></count-to>
                    FCFA
                  </h2>
                  <!-- <p class="mb-0 text-muted">
                  <span class="badge bg-light text-success mb-0">
                    <i class="ri-arrow-up-line align-middle"></i> 17.32 %
                  </span>
                  vs. previous month
                </p> -->
                </div>
                <div>
                  <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-info rounded-circle fs-4">
                      <i class="ri-ticket-2-line"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card body -->
          </div>
          <!-- end card-->
        </div>
        <!--end col-->
        <div class="col-xl-3 col-sm-6">
          <div class="card card-animate">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="fw-medium text-muted mb-0">En attente</p>
                  <h2 class="mt-4 ff-secondary fw-semibold">
                    <count-to
                      :startVal="0"
                      :endVal="
                        currentFieldVisitConfig.prospects.filter(
                          (item) => item.status == '0'
                        ).length
                      "
                      :duration="3000"
                    ></count-to>
                  </h2>
                  <!-- <p class="mb-0 text-muted">
                  <span class="badge bg-light text-danger mb-0">
                    <i class="ri-arrow-down-line align-middle"></i> 0.87 %
                  </span>
                  vs. previous month
                </p> -->
                </div>
                <div>
                  <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-warning rounded-circle fs-4">
                      <i class="mdi mdi-timer-sand"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card body -->
          </div>
        </div>
        <!--end col-->
        <div class="col-xl-3 col-sm-6">
          <div class="card card-animate">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="fw-medium text-muted mb-0">Payé</p>
                  <h2 class="mt-4 ff-secondary fw-semibold">
                    <count-to
                      :startVal="0"
                      :endVal="
                        currentFieldVisitConfig.prospects.filter(
                          (item) => item.status == '1'
                        ).length
                      "
                      :duration="3000"
                    ></count-to>
                  </h2>
                  <!-- <p class="mb-0 text-muted">
                  <span class="badge bg-light text-danger mb-0">
                    <i class="ri-arrow-down-line align-middle"></i> 2.52 %
                  </span>
                  vs. previous month
                </p> -->
                </div>
                <div>
                  <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-success rounded-circle fs-4">
                      <i class="ri-checkbox-circle-line"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card body -->
          </div>
        </div>
        <!--end col-->
        <div class="col-xl-3 col-sm-6">
          <div class="card card-animate">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="fw-medium text-muted mb-0">Total personnes</p>
                  <h2 class="mt-4 ff-secondary fw-semibold">
                    <count-to
                      :startVal="0"
                      :endVal="currentFieldVisitConfig.prospects.length"
                      :duration="3000"
                    ></count-to>
                  </h2>
                  <!-- <p class="mb-0 text-muted">
                  <span class="badge bg-light text-danger mb-0">
                    <i class="ri-arrow-down-line align-middle"></i> 2.52 %
                  </span>
                  vs. previous month
                </p> -->
                </div>
                <div>
                  <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-success rounded-circle fs-4">
                      <i class="ri-user-2-fill"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card body -->
          </div>
        </div>
        <!--end col-->
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card" id="orderList">
            <div class="card-header border-0">
              <div class="d-flex align-items-center">
                <h5 class="card-title mb-0 flex-grow-1">Historique</h5>
                <div class="flex-shrink-0">
                  <!-- <button
                  type="button"
                  class="btn btn-success add-btn"
                  data-bs-toggle="modal"
                  id="create-btn"
                  data-bs-target="#showModal"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Creer une
                  commande
                </button> -->
                  <!-- <button type="button" class="btn btn-info ms-1">
                  <i class="ri-file-download-line align-bottom me-1"></i> Importer
                </button> -->
                </div>
              </div>
            </div>
            <div
              class="card-body border border-dashed border-end-0 border-start-0"
            >
              <form>
                <div class="row g-3">
                  <div class="col-xxl-4 col-sm-6">
                    <div class="search-box">
                      <input
                        type="text"
                        class="form-control search"
                        placeholder="Rechercher l'ID de commande, le client, l'état de la commande ou quelque chose..."
                      />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-xxl-3 col-sm-6">
                    <div>
                      <flat-pickr
                        placeholder="Sélectionner la date"
                        v-model="date"
                        :config="config"
                        class="form-control flatpickr-input"
                        id="demo-datepicker"
                      ></flat-pickr>
                    </div>
                  </div>
                  <!--end col-->
                  <!-- <div class="col-xxl-3 col-sm-4">
                    <div>
                      <button
                        type="button"
                        class="btn btn-primary w-100"
                        @click="SearchData"
                      >
                        <i class="ri-equalizer-fill me-1 align-bottom"></i>
                        Filtres
                      </button>
                    </div>
                  </div> -->
                  <!--end col-->

                  <!-- Add this button somewhere appropriate in your template -->
                  <div class="col-xxl-2 col-sm-4">
                    <button
                      type="button"
                      class="btn btn-primary"
                      :disabled="!canAddVisit"
                      @click="handleOpenCreate"
                    >
                      Ajouter une visite
                    </button>
                  </div>
                  <!-- <div class="col-xxl-2 col-sm-4">
                    <div>
                      <button
                        type="button"
                        class="btn btn-warning w-100"
                        @click="isCreateModalOpen = true"
                      >
                        <i class="ri-add-line me-1 align-bottom"></i>
                        Ajouter
                      </button>
                    </div>
                  </div> -->
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
            <div class="card-body pt-0">
              <div>
                <ul
                  class="nav nav-tabs nav-tabs-custom nav-success mb-3"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active All py-3"
                      data-bs-toggle="tab"
                      id="All"
                      href="#home1"
                      role="tab"
                      @click="changecategory('All')"
                      aria-selected="true"
                    >
                      <i class="ri-store-2-fill me-1 align-bottom"></i>
                      Tous les prospects
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link py-3 Pending"
                      data-bs-toggle="tab"
                      id="Pending"
                      href="#pending"
                      role="tab"
                      @click="changecategory('0')"
                      aria-selected="false"
                    >
                      <i class="ri-truck-line me-1 align-bottom"></i>
                      En attente
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link py-3 1"
                      data-bs-toggle="tab"
                      id="1"
                      href="#done"
                      role="tab"
                      @click="changecategory('1')"
                      aria-selected="false"
                    >
                      <i class="ri-checkbox-circle-line me-1 align-bottom"></i>
                      Payées
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link py-3 -1"
                      data-bs-toggle="tab"
                      id="-1"
                      href="#cancelled"
                      role="tab"
                      @click="changecategory('-1')"
                      aria-selected="false"
                    >
                      <i class="ri-close-circle-line me-1 align-bottom"></i>
                      Annulées
                    </a>
                  </li>
                </ul>

                <div class="table-responsive table-card mb-1">
                  <table
                    class="table table-nowrap align-middle"
                    id="orderTable"
                  >
                    <thead class="text-muted table-light">
                      <tr class="text-uppercase">
                        <th scope="col" style="width: 25px">
                          <div class="form-check">
                            <!-- <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkAll"
                              value="option"
                            /> -->
                          </div>
                        </th>
                        <th class="sort" data-sort="customer_name">Client</th>
                        <th class="sort" data-sort="product_name">Montant</th>
                        <th class="sort" data-sort="product_name">Transport</th>
                        <th class="sort" data-sort="date">Souscrit le</th>
                        <!-- <th class="sort" data-sort="amount">Montant</th> -->
                        <th class="sort" data-sort="status">Statut</th>
                        <th class="sort" data-sort="city">Actions</th>
                      </tr>
                    </thead>
                    <tbody
                      class="list form-check-all"
                      v-for="(data, index) of resultQuery"
                      :key="index"
                    >
                      <tr
                        v-if="
                          statuscategory == 'All' ||
                          statuscategory == data.status
                        "
                      >
                        <th scope="row">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              :disabled="!data.has_paid || data.field_visit_id"
                              :checked="
                                selectedRows.some((row) => row.id === data.id)
                              "
                              @change="toggleRowSelection(data)"
                            />
                          </div>
                        </th>
                        <td class="customer_name">
                          <span>{{ data.fullname }}</span
                          ><br />
                          <span>{{ data.email }}</span> <br />
                          <span>{{ data.phone }}</span>
                        </td>
                        <td class="amount">{{ $formatPrice(data.amount) }}</td>
                        <td class="transport">
                          {{
                            JSON.parse(data.trip_mode).mode_fr ||
                            JSON.parse(data.trip_mode).name
                          }}
                        </td>
                        <td class="date">
                          {{ data.subscriptionDate }}
                          <!-- <small class="text-muted">02:21 AM</small> -->
                        </td>
                        <!-- <td class="amount">{{ data.amount }}FCFA</td> -->
                        <td class="status">
                          <span
                            class="badge text-uppercase"
                            :class="{
                              'badge-soft-success':
                                data.status == '1' || data.has_paid,
                              'badge-soft-danger': data.status == '-1',
                              'badge-soft-secondary': data.status == '0',
                            }"
                          >
                            {{ data.status_text }}
                          </span>
                          <div class="mt-2" v-if="data.paid_at">
                            Payé le :
                            <span class="text-success">
                              {{ new Date(data.paid_at).toLocaleString() }}
                            </span>
                            <br />
                            <small v-if="data.paid_by_admin">
                              (Hors plateforme)
                            </small>
                          </div>
                          <div v-if="data.field_visit" class="mt-2" style="border: 1px solid gray; border-radius: 8px; padding: 12px">
                            Enregistré pour la visite :
                            <RouterLink
                              :to="`/field-visits/${data.field_visit_id}/show`"
                              class="btn btn-primary btn-sm"
                            >
                              Voir la visite
                            </RouterLink>
                            <br />
                            <div>
                              <span>Prévue le : </span>

                              <span class="text-success">
                                {{
                                  new Date(
                                    data.field_visit.sheduled_at
                                  ).toLocaleString()
                                }}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td v-if="true">
                          <ul
                            v-if="!data.has_paid"
                            class="list-inline hstack gap-2 mb-0"
                          >
                            <li
                              class="list-inline-item edit"
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              data-bs-placement="top"
                              title="Valider le paiement"
                            >
                              <a
                                class="text-primary d-inline-block edit-item-btn"
                                @click="handleOpenUpdatePaymentModalOpen(data)"
                                style="cursor: pointer"
                              >
                                <i class="ri-check-double-fill fs-16"></i>
                              </a>
                            </li>
                            <!-- <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Remove"
                          >
                            <a
                              class="text-danger d-inline-block remove-item-btn"
                              @click="deletedata(data)"
                            >
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li> -->
                          </ul>
                        </td>
                        <!-- </div> -->
                      </tr>
                    </tbody>
                  </table>
                  <div class="noresult" style="display: none">
                    <div class="text-center">
                      <lottie
                        class="avatar-xl"
                        colors="primary:#121331,secondary:#08a88a"
                        :options="defaultOptions"
                        :height="75"
                        :width="75"
                      />
                      <h5 class="mt-2">Pardon! Aucun résultat trouvé</h5>
                      <p class="text-muted">
                        Nous avons recherché plus de 150 commandes Nous n'en
                        avons trouvé aucune commandes pour votre recherche.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                  <div class="pagination-wrap hstack gap-2">
                    <a
                      class="page-item pagination-prev disabled"
                      href="#"
                      v-if="page != 1"
                      @click="page--"
                    >
                      Precedent
                    </a>
                    <ul class="pagination listjs-pagination mb-0">
                      <li
                        v-for="(pageNumber, index) in pages.slice(
                          page - 1,
                          page + 5
                        )"
                        :key="index"
                        @click="page = pageNumber"
                        :class="{
                          active: pageNumber == page,
                          disabled: pageNumber == '...',
                        }"
                      >
                        <a class="page" href="#">{{ pageNumber }}</a>
                      </li>
                    </ul>
                    <a
                      class="page-item pagination-next"
                      href="#"
                      @click="page++"
                      v-if="page < pages.length"
                    >
                      Suivant
                    </a>
                  </div>
                </div>
              </div>

              <b-modal
                v-model="isUpdatePaymentModalOpen"
                hide-footer
                title="Valider le paiement"
                class="v-modal-custom"
                header-class="bg-light p-3"
              >
                <form v-if="currentProspect" action="#" id="editform">
                  <div class="modal-body">
                    <h5>Informations du prospect</h5>
                    <div>
                      <strong>Nom:</strong> {{ currentProspect.fullname }}
                    </div>
                    <div>
                      <strong>Contact:</strong> {{ currentProspect.phone }} /
                      {{ currentProspect.email }}
                    </div>
                    <div>
                      <strong>Localisation:</strong> {{ currentProspect.city }},
                      {{ currentProspect.quater }}
                    </div>

                    <div v-if="false" class="mb-3 mt-3">
                      <label for="payment-amount" class="form-label"
                        >Montant du paiement</label
                      >
                      <input
                        type="number"
                        id="payment-amount"
                        class="form-control"
                        v-model="paymentAmount"
                        placeholder="Entrez le montant du paiement"
                      />
                    </div>

                    <div class="mt-3 mb-3">
                      <label for="payment-notes" class="form-label"
                        >Notes</label
                      >
                      <textarea
                        id="payment-notes"
                        class="form-control"
                        v-model="paymentNotes"
                        rows="3"
                        placeholder="Entrez des notes supplémentaires"
                      ></textarea>
                    </div>

                    <p class="text-warning">
                      Êtes-vous sûr de vouloir valider ce paiement ?
                    </p>
                  </div>
                  <div class="modal-footer">
                    <div class="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        class="btn btn-light"
                        data-bs-dismiss="modal"
                        id="edtclosemodal"
                      >
                        Annuler
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        id="confirm-payment-btn"
                        @click="handleAdminPay"
                      >
                        Confirmer le paiement
                      </button>
                    </div>
                  </div>
                </form>
              </b-modal>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
    <!--end row-->

    <div class="card card-animate">
      <div class="card-header border-0">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 flex-grow-1">Envoyer un SMS Manuel</h5>
        </div>
      </div>
      <div class="card-body">
        <SmsSender
          :currentFieldVisitConfig="currentFieldVisitConfig"
          @reloadDatas="getCurrentVisit"
        />
      </div>
    </div>
  </Layout>
</template>
