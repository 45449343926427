<script>
import { mapGetters } from "vuex";

import SMSUtils from "../../../helpers/SMSUtils";
import Axios from "../../../config/axios";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import animationData from "@/components/widgets/msoeawqm.json";
import Swal from "sweetalert2";
import SMSCampaignList from "@/components/campaign/SMSCampaignList.vue";

export default {
  emits: ["reloadDatas"],
  components: {
    SMSCampaignList,
    flatPickr,
  },
  props: ["currentFieldVisitConfig"],
  data() {
    return {
      submitting: false,

      fields: {
        name: "",
        sms_content: "",
        status: "all",
      },

      new_event: null,
      loadings: [],

      rangeDateconfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      filterdate: null,
      filterdate1: null,
      filtervalue: "All",
      filtervalue1: "All",
      filtersearchQuery1: null,
      date: null,
      searchQuery: null,
      page: 1,
      perPage: 8,
      pages: [],
      defaultOptions: {
        animationData: animationData,
      },
    };
  },

  computed: {
    allTransactions() {
      return this.currentFieldVisitConfig?.notifications || [];
    },
    ...mapGetters("system", ["senders"]),
    smsCount() {
      return SMSUtils.countLength(this.smsExemple ?? "");
    },
    smsExemple() {
      return this.fields.sms_content
        .trim()
        .replace(/[\r\n]/gm, "")
        .replace(/\[nom\]/gm, "Hanniel TSASSE")
        .replace(/\[code\]/gm, "BT-LUCID344");
    },
    displayedPosts() {
      return this.paginate(this.allTransactions);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return data.reason.toLowerCase().includes(search);
        });
      } else if (this.filterdate !== null) {
        console.log(this.filterdate);
        if (this.filterdate !== null) {
          var date1 = this.filterdate.split(" to ")[0];
          var date2 = this.filterdate.split(" to ")[1];
        }
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.created_at) >= new Date(date1) &&
            new Date(data.created_at) <= new Date(date2)
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.filtervalue !== null) {
        return this.displayedPosts.filter((data) => {
          console.log(this.filtervalue);
          if (data.status === this.filtervalue || this.filtervalue == "All") {
            return data;
          } else {
            return null;
          }
        });
      } else {
        return this.displayedPosts;
      }
    },
  },

  watch: {
    allTransactions() {
      this.setPages();
    },
  },
  created() {
    this.initDatas();
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },

  methods: {
    SearchData() {
      this.searchQuery = this.filtersearchQuery1;
      this.filterdate = this.filterdate1;
      this.filtervalue = this.filtervalue1;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.allTransactions.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(allTransactions) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return allTransactions.slice(from, to);
    },
    async handleSendSMS() {
      this.$startLoading();
      await Axios.post(
        "/admin/fields/visit-configs/" +
          this.currentFieldVisitConfig.id +
          "/notify",
        {
          name: this.fields.name,
          filter: this.fields.status,
          message: this.fields.sms_content,
        }
      )
        .then(() => {
          this.$saSuccess("Votre message a été envoyé !");
        })
        .catch(() => {
          this.$saError("Erreur lors de l'envoie du message !");
        })
        .finally(() => {
          // this.$stopLoading();
          this.$emit("reloadDatas");
        });
      this.initDatas();
    },
    async handleResendSMS(target) {
      if (this.loadings.includes(target.id)) return;

      let resend = false;

      await Swal.fire({
        text: "Voulez-vous vraiment renvoyer le message ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, renvoyer",
        cancelButtonText: "Non, annuler",
        confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
        cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          resend = true;
        }
      });

      if (!resend) {
        return;
      }

      this.loadings.push(target.id);
      const index = this.loadings.length - 1;

      await Axios.patch(
        "/admin/subscriptions/resend-notification?id=" + target.id
      )
        .then(({ data }) => {
          if (data.error === false) {
            Swal.fire({
              icon: "success",
              text: "Message renvoyé !",
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "Erreur lors du renvoi du message !",
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text: "Erreur lors du renvoi du message !",
          });
        })
        .finally(() => {
          this.loadings.splice(index, 1);
          this.$emit("reloadDatas");
        });
    },

    initDatas() {
      this.fields = {
        sms_content: "",
        status: "all",
      };
    },

    handleReset() {
      this.initDatas();
    },

    async handleSubmit() {
      this.$startLoading();
    },
  },
};
</script>

<template>
  <form
    @submit.prevent="handleSubmit"
    class="card"
    style="border: 1px solid gray; border-radius: 8px; padding: 20px"
  >
    <div>
      <b-row>
        <b-col lg="12">
          <div class="mb-3">
            <label class="form-label" for="des-sender_name-name-input">
              Titre de la campagne
            </label>
            <input class="form-control" v-model="fields.name" required />
          </div>
        </b-col>

        <b-col lg="12">
          <div class="mb-3">
            <label class="form-label" for="des-sender_name-name-input">
              Diffuser à
            </label>
            <select
              class="form-select"
              id="des-sender_name-name-input"
              v-model="fields.status"
              required
            >
              <option :value="'all'">
                {{ "Tous les inscrits" }}
              </option>
              <option :value="'paid'">
                {{ "Payés" }}
              </option>
              <option :value="'pending'">
                {{ "En attente" }}
              </option>
              <option :value="'cancelled'">
                {{ "Annulés" }}
              </option>
            </select>
          </div>
        </b-col>

        <b-col lg="12">
          <div class="mb-3">
            <label class="form-label" for="des-sender_name-name-input">
              Nom d'envoie
            </label>
            <select
              class="form-select"
              id="des-sender_name-name-input"
              required
            >
              <option :value="'1'">
                {{ "BT Immo" }}
              </option>
            </select>
          </div>
        </b-col>

        <b-col lg="12">
          <b-row>
            <b-col lg="6">
              <div>
                <div class="d-flex justify-content-between">
                  <label class="form-label" for="des-info-description-input">
                    Contenu du SMS
                  </label>
                  <span class="text-warning">
                    {{ smsCount.remaining }} ({{ smsCount.messages }})
                  </span>
                </div>
                <textarea
                  v-model.trim="fields.sms_content"
                  class="form-control"
                  id="des-info-description-input"
                  rows="3"
                  required
                ></textarea>
              </div>
            </b-col>
            <b-col lg="6">
              <div>
                <label class="form-label" for="des-info-description-input">
                  Exemple d'affichage
                </label>
                <textarea
                  :value="smsExemple"
                  readonly
                  disabled
                  class="form-control"
                  id="des-info-description-input"
                  rows="3"
                  required
                ></textarea>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="col-lg-12">
      <div class="hstack gap-2 justify-content-end mt-3">
        <button
          type="button"
          class="btn btn-success"
          @click.prevent="handleSendSMS"
        >
          Envoyer le message
        </button>
        <button
          type="button"
          class="btn btn-soft-warning"
          :disabled="!isModified"
          @click.prevent="handleReset"
        >
          Annuler
        </button>
      </div>
    </div>
  </form>

  <div
    class="card"
    style="border: 1px solid gray; border-radius: 8px; padding: 20px"
  >
    <div class="col-lg-12">
      <div class="card" id="tasksList">
        <div class="card-header border-0">
          <div class="d-flex align-items-center">
            <h5 class="card-title mb-0 flex-grow-1">
              Notifications pour la visite
            </h5>
            <!-- <div class="flex-shrink-0">
                <button
                  class="btn btn-soft-danger me-1"
                  @click="deleteMultiple"
                >
                  <i class="ri-delete-bin-2-line"></i>
                </button>
                <button
                  class="btn btn-danger add-btn"
                  data-bs-toggle="modal"
                  href="#showmodal"
                  @click="addnew"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Create Task
                </button>
              </div> -->
          </div>
        </div>
        <div class="card-body border border-dashed border-end-0 border-start-0">
          <form>
            <div class="row g-3">
              <div class="col-xxl-5 col-sm-12">
                <div class="search-box">
                  <input
                    type="text"
                    class="form-control search bg-light border-light"
                    placeholder="Search for tasks or something..."
                    v-model="filtersearchQuery1"
                  />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <!--end col-->

              <div class="col-xxl-4 col-sm-4">
                <flat-pickr
                  v-model="filterdate1"
                  placeholder="Selectionner la date"
                  :config="rangeDateconfig"
                  class="form-control"
                ></flat-pickr>
              </div>
              <!--end col-->

              <!--end col-->
              <div class="col-xxl-3 col-sm-4">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  @click="SearchData"
                >
                  <i class="ri-equalizer-fill me-1 align-bottom"></i>
                  Filters
                </button>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <!--end card-body-->
        <div class="card-body">
          <SMSCampaignList :campaigns="currentFieldVisitConfig.notifications" />
        </div>
        <!--end card-body-->
      </div>
      <!--end card-->
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</template>
